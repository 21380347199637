<template>
  <div class="">
    <div class="product-match-card p-3 mx-3">
      <div class="mb-3">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="16" fill="#F1F1F4" />
          <path
            d="M18.5 11.8333V15.1666H19.3333V16.8333H16.8333V10.1666H18.5L16 6.83331L13.5 10.1666H15.1667V16.8333H12.6667V15.1083C13.25 14.8 13.6667 14.2083 13.6667 13.5C13.6667 12.4833 12.8417 11.6666 11.8333 11.6666C10.8167 11.6666 10 12.4833 10 13.5C10 14.2083 10.4167 14.8 11 15.1083V16.8333C11 17.2753 11.1756 17.6993 11.4882 18.0118C11.8007 18.3244 12.2246 18.5 12.6667 18.5H15.1667V21.0416C14.575 21.3416 14.1667 21.9583 14.1667 22.6666C14.1667 23.1529 14.3598 23.6192 14.7036 23.963C15.0475 24.3068 15.5138 24.5 16 24.5C16.4862 24.5 16.9525 24.3068 17.2964 23.963C17.6402 23.6192 17.8333 23.1529 17.8333 22.6666C17.8333 21.9583 17.425 21.3416 16.8333 21.0416V18.5H19.3333C19.7754 18.5 20.1993 18.3244 20.5118 18.0118C20.8244 17.6993 21 17.2753 21 16.8333V15.1666H21.8333V11.8333H18.5Z"
            fill="#B3AFB6"
          />
        </svg>
      </div>

      <p class="sidebarElementTitle" style="font-size: 14px">Product Match</p>
      <p class="sidebarElementSubTitle">
        Add or Import Products from your store in our Product Match page
      </p>

      <Button @click="goToConfiguration('product_match')" class="mt-2 w-100"
        >Go to Product Match
        <svg
          class="ml-2"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.7141 5.28597L5.28602 14.7141M14.7141 5.28597L14.7141 12.357M14.7141 5.28597L7.64304 5.28597"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Button>
    </div>
    <hr />
    <div class="px-3">
      <label for="" class="editor-label"> Choose an answer type: </label>
      <div class="editor-options-type-list">
        <div
          v-b-toggle.back-button-collapse
          class="selected-option-btn d-flex justify-content-between align-items-center"
        >
          <div class="d-flex gap align-items-center">
            <span v-html="optionsType[getViewTypeValue].svg"></span
            >{{ optionsType[getViewTypeValue].title }}
          </div>
          <div
            class="button-chevron"
            style="color: #b3afb6; font-weight: 500; font-size: 16px"
          >
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        </div>
        <b-collapse accordion="button-accordion" id="back-button-collapse">
          <hr />
          <div class="d-flex flex-column gap">
            <div
              v-b-toggle.back-button-collapse
              @click="setViewType(key)"
              v-for="(type, key) in optionsType"
              :key="key"
              class="selected-option-btns d-flex justify-content-between align-items-center"
            >
              <div class="d-flex gap align-items-center">
                <span v-html="type.svg"></span>{{ type.title }}
              </div>
              <div class="option-radio-button">
                <input
                  type="radio"
                  :value="key"
                  name="radio-select-option"
                  :checked="getViewTypeValue == key"
                />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="!isDesktop"
        class="mt-2 d-flex align-items-center justify-content-between"
      >
        <label class="editor-label">Use Separate Mobile View</label>
        <b-form-checkbox
          :id="`useSeperateMobileViewType-${Date.now()}`"
          v-model="block.useSeperateMobileViewType"
        ></b-form-checkbox>
      </div>
    </div>
    <hr>
    <div class="" v-if="getViewTypeValue == 'groupProducts'" name="Group Product Settings">
      <div
        v-b-toggle.group-product-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Group Products Settings
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="group-product-setting-collapse"
      >
       <div class="mx-3 mb-2 mt-2 ">
        <label class="editor-label">Group By:</label>
        <div    class="select-per-page-count">
        <select class="w-100 m-0 p-0" v-model="block.style.bulletDescriptionfontFamily"  name="" id="">
  <option value="Tags">Tags</option>
  <option
                    v-if="checkIsCustomCollectionMedisClient"
                    value="Collections"
                  >
                    Collections
                  </option>
</select>
</div>
<div class="mt-2">
  
  <div
        class="d-flex  mb-1 py-1 align-items-center justify-content-between"
      >
        <label class="editor-label">Limit Group Products</label>
        <b-form-checkbox
          :id="`limit-group-${Date.now()}`"
          v-model="block.groupProducts.limitProductsPerGroup"
        ></b-form-checkbox>
      </div>
</div>
<div v-if="block.groupProducts.limitProductsPerGroup"
            class="d-flex mt-2 justify-content-between mt-2 align-items-center"
          >
            <label for="" class="editor-label"> Max Product </label>
            <div class="select-per-page-count w-25 d-flex between">
              <div class="">
                <input
                  type="number"
                  :min="0"
                  class="w-100"
                  v-model.number="block.groupProducts.productsPerGroup"
                  id=""
                />
              </div>
            </div>
          </div>
       </div>
       <hr>
       <div class="px-3">
        <div class="">
          <label for="" class="editor-label">
            Select your {{ getGroupBy }} group
          </label>
          <div v-if="isFetchingGroupsLoading"
        class="integrationImgae bg-preload"
        style="height: 27px; width: 100%; border-radius: 8px">
        <div class="into-preload"></div>
      </div>
      <div
                  v-else
                  class="GroupSelectContainer"
               
                >
                  <multiselect
                    v-if="groupProducts.length"
                    :options="getGroupOptions"
                    :multiple="true"
                    v-model="block.groupProducts.groupData"
                    class="multiSelect p-0"
                    :placeholder="`Search ${getGroupBy}`"
                    :close-on-select="true"
                    :searchable="true"
                  >
                  </multiselect>
                  <p
                    class="sidebarElementSubTitle m-0"
                    style="font-size: 14px; font-weight: 500"
                    v-else
                  >
                    No groups found
                  </p>
                </div>
        </div>
       </div>
       <hr>
       <div class="mx-3 mt-2 ">
        <FontFamily :label="'Text Style'"  v-model="block.style.groupProductsStyle.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input  type="number" :min="0" v-model="block.style.groupProductsStyle.fontSize" placeholder="Font size " class="editor-input-control w-100 input-bg-color border-0">

</div>
    <FontStyles :textBold.sync="block.style.groupProductsStyle.fontWeight" 
  :textItalic.sync=" block.style.groupProductsStyle.fontStyle"
  :textUnderLine.sync="block.style.groupProductsStyle.textDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex gap">
    <TextAlign v-model=block.style.groupProductsStyle.textAlign ></TextAlign>
 
  </div>
 
       </div>
       <hr>
       <div class="px-3 mb-2">
  <SelectColor v-model="block.style.groupProductsStyle.color" :opacity.sync="block.style.bulletDescriptiontitleTextOpacity" :label="'Text Color:'"></SelectColor>

</div>
      </b-collapse>
    </div>
    <div class=""  v-if="block.useProductVariant&&this.getUserType=='shopify'" name="Product Variant Style">
      <div
        v-b-toggle.product-variant-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Product Variant Style
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="product-variant-setting-collapse"
       
      >
      <div class="mx-3 mt-2 ">
        <div class="mb-2">
          <label class="editor-label">Text:</label>
          <input   type="text" v-model="block.selectVariantText"  :placeholder="`Product Variant Text`" class="editor-input-control ml-1 w-100">
          
        </div>
        <FontFamily :label="'Font Style:'"  v-model="block.style.productVariantStyles.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center w-100" title="Font size (px)">
    <div class="d-flex align-items-center w-100" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input  type="number" :min="0" v-model="block.style.productVariantStyles.fontSize" placeholder="Font size " class="editor-input-control w-100 input-bg-color border-0">

</div>
  
  </div>
  
 
       </div>
       <hr>
       <div class="px-3 mb-2">
         <SelectColor v-model="block.style.productVariantStyles.color" :isOpacity="false" :label="'Text Color:'"></SelectColor>
         <SelectColor v-model="block.style.productVariantStyles.arrowColo" class="mt-2" :isOpacity="false" :label="'Arrow Color:'"></SelectColor>
         <SelectColor v-model="block.style.productVariantStyles.background" class="mt-2" :isOpacity="false" :label="'Background Color:'"></SelectColor>
         <SelectColor v-model="block.style.productVariantStyles.arrowBackground" class="mt-2" :isOpacity="false" :label="'Arrow Background Color:'"></SelectColor>
</div>
       
      </b-collapse>
    </div>
    <div class="" name="Element Settings">
      <div
        v-b-toggle.element-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Elements to show
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="element-setting-collapse"
      >
       <div class="mx-3 mb-2 mt-2 ">
        <div
        v-for="(element,index) in elementsToShow" :key="index"
        class="d-flex element-card px-2 mb-1 py-1 align-items-center justify-content-between"
      >
        <label class="editor-label m-0">{{element.title}}</label>
        <b-form-checkbox
         :value="element.variable=='hideDescription'?false:true"
      :unchecked-value="element.variable=='hideDescription'?true:false"
          :id="`${element.title}-${Date.now()}`"
          v-model="block[`${element.variable}`]"
        ></b-form-checkbox>
      </div>

      <div
        
        class="d-flex element-card px-2 mb-1 py-1 align-items-center justify-content-between"
      >
        <label class="editor-label m-0">Price</label>
        <b-form-checkbox
       :value="false"
      :unchecked-value="true"
      @change="updateHidePriceFunc()"
          :id="`show-${Date.now()}`"
          v-model="hidePrice"
        ></b-form-checkbox>
      </div>
      <div
      v-if="block.viewType == 'detailList' && !block.hideDescription"
        class="d-flex element-card px-2 mb-1 py-1 align-items-center justify-content-between"
      >
        <label class="editor-label m-0">Description (Hierarchy)</label>
        <b-form-checkbox
       :value="false"
      :unchecked-value="true"
    
          :id="`des-Hierarchy-${Date.now()}`"
          v-model="block.showHeirarchyDesciption"
        ></b-form-checkbox>
      </div>
      <div class="" v-if="this.getUserType=='shopify'"  @click="openUpgradePlansModalForAddToCart('variant')">
        <div
    
        class="d-flex element-card px-2 mb-1 py-1 align-items-center justify-content-between"
      >
        <label class="editor-label m-0">Show product variants</label>
        <b-form-checkbox
          :id="`product-variant-${Date.now()}`"
          :disabled="!showAddToCartAndVariant"
          v-model="block.useProductVariant"
        ></b-form-checkbox>
      </div>
      </div>
      <div class="mb-2" v-if="!hidePrice">
        <label for="" class="editor-label">
    Currency:
</label>
<div class="d-flex gap">
  <button :class="block.style.Currencyposition=='left'?'btn-active-arrangement':''" @click="()=>{block.style.Currencyposition='left';updateHidePriceFunc()}" class="btn-arrangement w-100">
    <div class="d-flex gap align-items-center justify-content-center">
      <span>Left</span>
      <svg style="transform: rotate(90deg);" width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.24002 10.636L4.24002 1.34429C4.24002 1.10818 4.32002 0.910403 4.48002 0.750959C4.64002 0.591514 4.8378 0.511514 5.07335 0.510958C5.30891 0.510403 5.50669 0.590403 5.66669 0.750959C5.82669 0.911514 5.90669 1.10929 5.90669 1.34429L5.90669 10.636L8.28169 8.26096C8.44835 8.09429 8.6428 8.01429 8.86502 8.02096C9.08724 8.02763 9.28169 8.10763 9.44835 8.26096C9.61502 8.42763 9.70169 8.62568 9.70835 8.85513C9.71502 9.08457 9.6353 9.28235 9.46919 9.44846L5.65669 13.261C5.57335 13.3443 5.48308 13.4035 5.38585 13.4385C5.28863 13.4735 5.18446 13.4907 5.07335 13.4901C4.96224 13.4896 4.85808 13.4723 4.76085 13.4385C4.66363 13.4046 4.57335 13.3454 4.49002 13.261L0.67752 9.44846C0.510854 9.28179 0.430854 9.08401 0.437521 8.85513C0.444188 8.62624 0.531131 8.42818 0.698353 8.26096C0.86502 8.10818 1.05947 8.02818 1.28169 8.02096C1.50391 8.01374 1.69835 8.09374 1.86502 8.26096L4.24002 10.636Z" fill="currentColor"/>
</svg>

    </div>
  </button>
  <button  @click="()=>{block.style.Currencyposition='right';updateHidePriceFunc();}" :class="block.style.Currencyposition=='right'?'btn-active-arrangement':''" class="btn-arrangement w-100">
    <div class="d-flex gap align-items-center justify-content-center">
      <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.2083 5.83325L0.91668 5.83325C0.680569 5.83325 0.482791 5.75325 0.323346 5.59325C0.163902 5.43325 0.0839019 5.23547 0.0833464 4.99992C0.0827908 4.76436 0.162791 4.56659 0.323346 4.40659C0.483902 4.24659 0.68168 4.16658 0.91668 4.16658L10.2083 4.16658L7.83335 1.79158C7.66668 1.62492 7.58668 1.43047 7.59335 1.20825C7.60001 0.986029 7.68001 0.791585 7.83335 0.624918C8.00001 0.458252 8.19807 0.371585 8.42751 0.364918C8.65696 0.358252 8.85474 0.437974 9.02085 0.604085L12.8333 4.41658C12.9167 4.49992 12.9758 4.5902 13.0108 4.68742C13.0458 4.78464 13.0631 4.88881 13.0625 4.99992C13.062 5.11103 13.0447 5.2152 13.0108 5.31242C12.977 5.40964 12.9178 5.49992 12.8333 5.58325L9.02085 9.39575C8.85418 9.56242 8.6564 9.64242 8.42751 9.63575C8.19862 9.62908 8.00057 9.54214 7.83335 9.37492C7.68057 9.20825 7.60057 9.01381 7.59335 8.79158C7.58612 8.56936 7.66612 8.37492 7.83335 8.20825L10.2083 5.83325Z" fill="currentColor"/>
</svg>

<span>Right</span>
    </div>
  </button>
 
</div>
      </div>
      <div class="" v-if="block.showProductRank">
      <div class="d-flex align-items-center mb-2"   v-for="(rankOption, index) in rankOptionArr"
              :key="index">
             
                <input  type="text" v-model="rankOption.text"  :placeholder="`Rank ${index}`" class="editor-input-control ml-1 w-100">
<div class="p-1 ml-1 delete-option-button"   @click.stop="deleteRankOption(index)">
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.99999 13.8333C0.99999 14.2754 1.17558 14.6993 1.48815 15.0118C1.80071 15.3244 2.22463 15.5 2.66666 15.5H9.33332C9.77535 15.5 10.1993 15.3244 10.5118 15.0118C10.8244 14.6993 11 14.2754 11 13.8333V3.83333H0.99999V13.8333ZM2.66666 5.5H9.33332V13.8333H2.66666V5.5ZM8.91666 1.33333L8.08332 0.5H3.91666L3.08332 1.33333H0.166656V3H11.8333V1.33333H8.91666Z" fill="currentColor"/>
</svg>

</div>
              </div>
              <button @click="addRankOption()"  class="add-new-option py-2">
           
    <span> + Add New Rank</span>
            </button>
          </div>
       </div>
      </b-collapse>
    </div>
    <div class="" v-if="block.useBulletDescription" name="Bullet Description Settings">
      <div
        v-b-toggle.bullet-description-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Bullet Description Settings
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="bullet-description-setting-collapse"
      >
       <div class="mx-3 mt-2 ">
        <FontFamily  v-model="block.style.bulletDescriptionfontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input  type="number" :min="0" v-model="block.style.bulletDescriptionfontSize" placeholder="Font size " class="editor-input-control w-100 input-bg-color border-0">

</div>
    <FontStyles :textBold.sync="block.style.bulletDescriptionfontWeight" 
  :textItalic.sync=" block.style.bulletDescriptionfontStyle"
  :textUnderLine.sync="block.style.bulletDescriptiontextDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex gap">
    <TextAlign v-model=block.style.bulletDescriptiontextAlign ></TextAlign>
 
  </div>
  <div class="d-flex justify-content-between mt-2 align-items-center"  >
  <label for="" class="editor-label m-0">
    Bullet Icon:
</label>
  <div    class="select-per-page-count">
  <select class="w-100 m-0 p-0" v-model="block.selectedBulletIcon"  name="" id="">
  <option value="" disabled>Select</option>
  <option value="fa-check"><i class="fa fa-check" aria-hidden="true"></i> Check</option>
  <option value="fa-check-circle">Check Circle</option>
  <option value="fa-check-square">Check Square</option>
</select>
</div>

</div>
       </div>
       <hr>
       <div class="px-3 mb-2">
  <SelectColor v-model="block.style.bulletDescriptiontitleColor" :opacity.sync="block.style.bulletDescriptiontitleTextOpacity" :label="'Text Color:'"></SelectColor>

</div>
      </b-collapse>
    </div>
    <div class="" name="Display Settings">
      <div
        v-b-toggle.display-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Display Settings
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="display-setting-collapse"
      >
        <div class="px-3 mb-2"  v-if="getViewTypeValue == 'detailOnly'">
          <div class="mt-2 d-flex align-items-center justify-content-between">
            <label class="editor-label">Show List View</label>
            <b-form-checkbox
              :id="`showListView-${Date.now()}`"
              v-model="block.showListView"
            ></b-form-checkbox>
          </div>

          <div class="mt-2 d-flex align-items-center justify-content-between">
            <label class="editor-label">Limit Product</label>
            <b-form-checkbox
              :id="`limitPRoduct-${Date.now()}`"
              v-model="block.limitProduct"
            ></b-form-checkbox>
          </div>
          <div v-if="block.limitProduct"
            class="d-flex mt-2 justify-content-between mt-2 align-items-center"
          >
            <label for="" class="editor-label"> Max Product </label>
            <div class="select-per-page-count w-25 d-flex between">
              <div class="">
                <input
                  type="number"
                  :min="0"
                  class="w-100"
                  v-model.number="block.limitProductLength"
                  id=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="px-3 mb-2" v-else>
          <div class="d-flex justify-content-between mt-2 align-items-center"  >
  <label for="" class="editor-label">
    Products per row: {{ isDesktop?'':'(Mobile)' }}
</label>
  <div    class="select-per-page-count">
  <select class="w-100 m-0 p-0" v-model="block.productsPerPage"  v-if="isDesktop" name="" id="">
  <option value="" disabled>Select</option>
  <option :value="value" v-for="value in 4" :key="value">{{ value }}</option>
</select>
<select class="w-100 m-0 p-0" v-model="block.productsPerPageMobile"  v-else name=""  id="">
  <option value="" disabled>Select</option>
  <option :value="value" v-for="value in 2" :key="value">{{ value }}</option>
</select>
</div>
</div>
<div class="d-flex justify-content-between mt-2 align-items-center"  >
  <label for="" class="editor-label">
    Rows: {{ isDesktop?'':'(Mobile)' }}
</label>
  <div    class="select-per-page-count">
  <select class="w-100 m-0 p-0" v-model="block.rows"  v-if="isDesktop" name="" id="">
  <option value="" disabled>Select</option>
  <option :value="value" v-for="value in 10" :key="value">{{ value }}</option>
</select>
<select class="w-100 m-0 p-0" v-model="block.mobileRows"  v-else name=""  id="">
  <option value="" disabled>Select</option>
  <option :value="value" v-for="value in 10" :key="value">{{ value }}</option>
</select>
</div>
</div>
        </div>
      </b-collapse>
    </div>
    <div class="" name="Image Settings">
      <div
        v-b-toggle.image-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Image Settings
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="image-setting-collapse"
        class="mt-2"
      >
       
        <div class="px-3">
<div class="d-flex justify-content-between mt-2 align-items-center"  >
  <label for="" class="editor-label">
    Rows:
</label>
  <div    class="select-per-page-count">
  <select class="w-100 m-0 p-0" v-model="block.style.imageRatio"  name="" id="">
  <option value="" disabled>Select</option>
  <option :value="value.value" v-for="value in imageRatio" :key="value.value">{{ value.title }}</option>
</select>
</div>
</div>
<div class="d-flex justify-content-between mt-2 align-items-center"  >
  <label for="" class="editor-label">
    Scale:
</label>
  <div    class="select-per-page-count">
  <select class="w-100 m-0 p-0" v-model="block.style.imageFit"  name="" id="">
  <option value="" disabled>Select</option>
  <option :value="value.value" v-for="value in imageScale" :key="value.value">{{ value.title }}</option>
</select>
</div>
</div>
        </div>
      </b-collapse>
    </div>
    <div class="" name="Card Style">
      <div
        v-b-toggle.card-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Card Styles
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="card-setting-collapse"
       
      >
   <div class="px-3" v-if="getViewTypeValue == 'flipCards'">
    <div class="" title="Height (px)" style="gap:4px">
<label class="editorStyleTitle"  >Height:</label>
  <input type="number" :min="0" v-model="block.style.flipCardsHeight"  placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
   
   </div>
   <hr  v-if="getViewTypeValue == 'flipCards'">
       <div class="px-3 mb-2">
  <SelectColor v-model="block.style.borderColor"  :isOpacity="false" :label="'Border Color:'"></SelectColor>
  <SelectColor v-model="block.style.backgroundColor" class="mt-2" :isOpacity="false" :label="'Background Color:'"></SelectColor>

</div>
       <hr>
       <div class="px-3">
  <label for="" class="editor-label">Stroke:</label>
  <Stroke  :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
 </div>
 <hr>
 <div class="px-3 mb-2">
  <padding  v-if="isDesktop" :right.sync="block.style.pagePaddingRight" label="'Padding'" :left.sync="block.style.pagePaddingLeft" :top.sync="block.style.pagePaddingTop" :bottom.sync="block.style.pagePaddingBottom"></padding>
  <padding v-else :label="'Padding (Mobile)'" :right.sync="block.style.pageMobilePaddingRight" :left.sync="block.style.pageMobilePaddingLeft" :top.sync="block.style.pageMobilePaddingTop" :bottom.sync="block.style.pageMobilePaddingBottom"></padding>
  
 </div>
      </b-collapse>
    </div>
    <div class="" name="Text Style">
      <div
        v-b-toggle.text-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Text Styles
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="text-setting-collapse"
       
      >
      <div class="mx-3 mt-2 ">
        <FontFamily :label="'Product title:'"  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input  type="number" :min="0" v-model="block.style.fontSize" placeholder="Font size " class="editor-input-control w-100 input-bg-color border-0">

</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
  <div class="d-flex gap">
    <TextAlign v-model=block.style.textAlign ></TextAlign>
 
  </div>
 
       </div>
       <hr>
       <div class="px-3 mb-2">
  <SelectColor v-model="block.style.titleColor"  :opacity.sync="block.style.titleTextOpacity" :label="'Text Color:'"></SelectColor>
  <SelectColor v-model="block.style.priceColor" class="mt-2" :isOpacity="false" :label="'Price Color:'"></SelectColor>

</div>
       
      </b-collapse>
    </div>
    <div class="mb-3" name="Button Style">
      <div
        v-b-toggle.button-setting-collapse
        style="
          background: #f7f7f7;
          cursor: pointer;
          border-bottom: 1px solid #d2d8e0;
          border-top: 1px solid #d2d8e0;
        "
        class="button-editor-heading px-3 py-2 d-flex justify-content-between align-items-center"
      >
        <div style="color: #18191c; font-weight: 500; font-size: 16px" class="">
          Button Styles
        </div>
        <div
          class="button-chevron"
          style="color: #b3afb6; font-weight: 500; font-size: 16px"
        >
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
      <b-collapse
        accordion="button-accordion"
        id="button-setting-collapse"
       
      >
      <div class="mx-3 mt-2 ">
        <div class="">
          <label class="editor-label">Text:</label>
          <input  v-if="block.useAddToCart"  type="text" v-model="block.useAddToCartText"  :placeholder="`Add to cart`" class="editor-input-control ml-1 w-100">
          <input  v-else type="text" v-model="block.buttonText"  :placeholder="`Button`" class="editor-input-control ml-1 w-100">
          <div
      
        class="d-flex  mt-2 align-items-center justify-content-between"
      >
        <label class="editor-label m-0">Add to cart:</label>
        <div class="" @click="openUpgradePlansModalForAddToCart('variant')">
        <b-form-checkbox
          :disabled="!showAddToCartAndVariant"
          :id="`addToCart-${Date.now()}`"
          v-model="block.useAddToCart"
        ></b-form-checkbox>
      </div>
      </div>
        </div>
       </div>
       <hr>
       <div class=" mt-2 mx-3">
        <FontFamily :label="'Product title:'"  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center w-100" title="Font size (px)">
    <div class="d-flex align-items-center w-100" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
<input  type="number" :min="0" v-model="block.style.buttonDesktopFontSize" placeholder="Font size " class="w-100 editor-input-control w-100 input-bg-color border-0">

</div>
  </div>
 
       </div>
       <hr>
       <div class="px-3 mb-2">
    <SelectColor v-model="block.style.buttonTextColor" class="mt-2" :isOpacity="false" :label="'Text Color:'"></SelectColor>
  <SelectColor v-model="block.style.buttonBgColor"  :isOpacity="false" :label="'Background Color:'"></SelectColor>

</div>
       <hr>
       <div class="px-3">
  <label for="" class="editor-label">Stroke:</label>
  <Stroke  :borderSize.sync="block.style.buttonBorderSize"
  :borderRadius.sync="block.style.buttonBorderRadius"  ></Stroke>
 </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Button from "../../../../Dashboard/Layout/Button.vue";
import { mapGetters, mapMutations } from "vuex";
import fontFamilyList from "../../../staticPages/fontFamilyList.js";
import FontFamily from "../MiniComponents/FontFamily.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Padding from "../MiniComponents/Padding.vue";
import Stroke from "../MiniComponents/Stroke.vue";

export default {
  components: { Multiselect,Padding, Stroke, Button, FontFamily,TextAlign,FontStyles,SelectColor },
  props: {
    block: Object,
    index: Number,
    isDesktop: Boolean,
  },
  data() {
    return {
      count: 0,
      rankOptionArr: [],
      hidePrice: null,
      isFetchingGroupsLoading: false,
      groupProducts: [],
      selectedBlock: "",
      optionsType: {
        detailOnly: {
          title: "Single",
          svg: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 2H10.6667C12.5067 2 14 3.49333 14 5.33333V10.6667C14 12.5067 12.5067 14 10.6667 14H5.33333C3.49333 14 2 12.5067 2 10.6667V5.33333C2 3.49333 3.49333 2 5.33333 2ZM5.33333 3.33333C4.22667 3.33333 3.33333 4.22667 3.33333 5.33333V10.6667C3.33333 11.7733 4.22667 12.6667 5.33333 12.6667H10.6667C11.7733 12.6667 12.6667 11.7733 12.6667 10.6667V5.33333C12.6667 4.22667 11.7733 3.33333 10.6667 3.33333H5.33333Z" fill="currentColor"/>
</svg>

`,
        },
        list: {
          title: "Multiple",
          svg: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_26_3897)">
<path d="M4.66667 0H2.66667C1.95942 0 1.28115 0.280952 0.781049 0.781049C0.280952 1.28115 0 1.95942 0 2.66667L0 4.66667C0 5.37391 0.280952 6.05219 0.781049 6.55229C1.28115 7.05238 1.95942 7.33333 2.66667 7.33333H4.66667C5.37391 7.33333 6.05219 7.05238 6.55229 6.55229C7.05238 6.05219 7.33333 5.37391 7.33333 4.66667V2.66667C7.33333 1.95942 7.05238 1.28115 6.55229 0.781049C6.05219 0.280952 5.37391 0 4.66667 0ZM6 4.66667C6 5.02029 5.85952 5.35943 5.60948 5.60948C5.35943 5.85952 5.02029 6 4.66667 6H2.66667C2.31304 6 1.97391 5.85952 1.72386 5.60948C1.47381 5.35943 1.33333 5.02029 1.33333 4.66667V2.66667C1.33333 2.31304 1.47381 1.97391 1.72386 1.72386C1.97391 1.47381 2.31304 1.33333 2.66667 1.33333H4.66667C5.02029 1.33333 5.35943 1.47381 5.60948 1.72386C5.85952 1.97391 6 2.31304 6 2.66667V4.66667Z" fill="currentColor"/>
<path d="M13.3334 3.05176e-05H11.3334C10.6262 3.05176e-05 9.94789 0.280982 9.4478 0.781079C8.9477 1.28118 8.66675 1.95945 8.66675 2.6667V4.6667C8.66675 5.37394 8.9477 6.05222 9.4478 6.55232C9.94789 7.05241 10.6262 7.33336 11.3334 7.33336H13.3334C14.0407 7.33336 14.7189 7.05241 15.219 6.55232C15.7191 6.05222 16.0001 5.37394 16.0001 4.6667V2.6667C16.0001 1.95945 15.7191 1.28118 15.219 0.781079C14.7189 0.280982 14.0407 3.05176e-05 13.3334 3.05176e-05ZM14.6667 4.6667C14.6667 5.02032 14.5263 5.35946 14.2762 5.60951C14.0262 5.85955 13.687 6.00003 13.3334 6.00003H11.3334C10.9798 6.00003 10.6407 5.85955 10.3906 5.60951C10.1406 5.35946 10.0001 5.02032 10.0001 4.6667V2.6667C10.0001 2.31308 10.1406 1.97394 10.3906 1.72389C10.6407 1.47384 10.9798 1.33336 11.3334 1.33336H13.3334C13.687 1.33336 14.0262 1.47384 14.2762 1.72389C14.5263 1.97394 14.6667 2.31308 14.6667 2.6667V4.6667Z" fill="currentColor"/>
<path d="M4.66667 8.66672H2.66667C1.95942 8.66672 1.28115 8.94767 0.781049 9.44777C0.280952 9.94786 0 10.6261 0 11.3334L0 13.3334C0 14.0406 0.280952 14.7189 0.781049 15.219C1.28115 15.7191 1.95942 16.0001 2.66667 16.0001H4.66667C5.37391 16.0001 6.05219 15.7191 6.55229 15.219C7.05238 14.7189 7.33333 14.0406 7.33333 13.3334V11.3334C7.33333 10.6261 7.05238 9.94786 6.55229 9.44777C6.05219 8.94767 5.37391 8.66672 4.66667 8.66672ZM6 13.3334C6 13.687 5.85952 14.0261 5.60948 14.2762C5.35943 14.5262 5.02029 14.6667 4.66667 14.6667H2.66667C2.31304 14.6667 1.97391 14.5262 1.72386 14.2762C1.47381 14.0261 1.33333 13.687 1.33333 13.3334V11.3334C1.33333 10.9798 1.47381 10.6406 1.72386 10.3906C1.97391 10.1405 2.31304 10.0001 2.66667 10.0001H4.66667C5.02029 10.0001 5.35943 10.1405 5.60948 10.3906C5.85952 10.6406 6 10.9798 6 11.3334V13.3334Z" fill="currentColor"/>
<path d="M13.3334 8.66672H11.3334C10.6262 8.66672 9.94789 8.94767 9.4478 9.44777C8.9477 9.94786 8.66675 10.6261 8.66675 11.3334V13.3334C8.66675 14.0406 8.9477 14.7189 9.4478 15.219C9.94789 15.7191 10.6262 16.0001 11.3334 16.0001H13.3334C14.0407 16.0001 14.7189 15.7191 15.219 15.219C15.7191 14.7189 16.0001 14.0406 16.0001 13.3334V11.3334C16.0001 10.6261 15.7191 9.94786 15.219 9.44777C14.7189 8.94767 14.0407 8.66672 13.3334 8.66672ZM14.6667 13.3334C14.6667 13.687 14.5263 14.0261 14.2762 14.2762C14.0262 14.5262 13.687 14.6667 13.3334 14.6667H11.3334C10.9798 14.6667 10.6407 14.5262 10.3906 14.2762C10.1406 14.0261 10.0001 13.687 10.0001 13.3334V11.3334C10.0001 10.9798 10.1406 10.6406 10.3906 10.3906C10.6407 10.1405 10.9798 10.0001 11.3334 10.0001H13.3334C13.687 10.0001 14.0262 10.1405 14.2762 10.3906C14.5263 10.6406 14.6667 10.9798 14.6667 11.3334V13.3334Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_26_3897">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`,
        },
        detailList: {
          title: "Hierarchy",
          svg: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_26_3906)">
<path d="M7.33333 0.666656H3C1.346 0.666656 0 2.01266 0 3.66666V5.99999C0 6.73532 0.598 7.33332 1.33333 7.33332H7.33333C8.06867 7.33332 8.66667 6.73532 8.66667 5.99999V1.99999C8.66667 1.26466 8.06867 0.666656 7.33333 0.666656ZM1.33333 5.99999V3.66666C1.33333 2.74732 2.08133 1.99999 3 1.99999H7.33333V5.99999H1.33333ZM13 1.99999H11.3333C10.598 1.99999 10 2.59799 10 3.33332V12C10 12.7353 10.598 13.3333 11.3333 13.3333H13C14.654 13.3333 16 11.9873 16 10.3333V4.99999C16 3.34599 14.654 1.99999 13 1.99999ZM14.6667 10.3333C14.6667 11.2527 13.9187 12 13 12H11.3333V3.33332H13C13.9187 3.33332 14.6667 4.08066 14.6667 4.99999V10.3333ZM7.33333 8.66666H2.66667C1.93133 8.66666 1.33333 9.26466 1.33333 9.99999V12.3333C1.33333 13.9873 2.67933 15.3333 4.33333 15.3333H7.33333C8.06867 15.3333 8.66667 14.7353 8.66667 14V9.99999C8.66667 9.26466 8.06867 8.66666 7.33333 8.66666ZM7.33333 14H4.33333C3.41467 14 2.66667 13.2527 2.66667 12.3333V9.99999H7.33333V14Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_26_3906">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

`,
        },
        groupProducts: {
          title: "Group Products",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<g clip-path="url(#clip0_26_3912)">
<path d="M12.6667 0H3.33333C1.49533 0 0 1.49533 0 3.33333V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V3.33333C16 1.49533 14.5047 0 12.6667 0ZM14.6667 12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V3.33333C1.33333 2.23067 2.23067 1.33333 3.33333 1.33333H12.6667C13.7693 1.33333 14.6667 2.23067 14.6667 3.33333V12.6667ZM6.33333 2.66667H3.66667C3.11533 2.66667 2.66667 3.11533 2.66667 3.66667V6.33333C2.66667 6.88467 3.11533 7.33333 3.66667 7.33333H6.33333C6.88467 7.33333 7.33333 6.88467 7.33333 6.33333V3.66667C7.33333 3.11533 6.88467 2.66667 6.33333 2.66667ZM6 6H4V4H6V6ZM12.3333 2.66667H9.66667C9.11533 2.66667 8.66667 3.11533 8.66667 3.66667V6.33333C8.66667 6.88467 9.11533 7.33333 9.66667 7.33333H12.3333C12.8847 7.33333 13.3333 6.88467 13.3333 6.33333V3.66667C13.3333 3.11533 12.8847 2.66667 12.3333 2.66667ZM12 6H10V4H12V6ZM6.33333 8.66667H3.66667C3.11533 8.66667 2.66667 9.11533 2.66667 9.66667V12.3333C2.66667 12.8847 3.11533 13.3333 3.66667 13.3333H6.33333C6.88467 13.3333 7.33333 12.8847 7.33333 12.3333V9.66667C7.33333 9.11533 6.88467 8.66667 6.33333 8.66667ZM6 12H4V10H6V12ZM12.3333 8.66667H9.66667C9.11533 8.66667 8.66667 9.11533 8.66667 9.66667V12.3333C8.66667 12.8847 9.11533 13.3333 9.66667 13.3333H12.3333C12.8847 13.3333 13.3333 12.8847 13.3333 12.3333V9.66667C13.3333 9.11533 12.8847 8.66667 12.3333 8.66667ZM12 12H10V10H12V12Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_26_3912">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>
`,
        },
        flipCards: {
          title: "Flip Cards",
          svg: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_26_3918)">
<path d="M14.6667 5.99989H13.3333C12.598 5.99989 12 6.59789 12 7.33323V8.66656C12 9.40189 12.598 9.99989 13.3333 9.99989H14.6667C15.402 9.99989 16 9.40189 16 8.66656V7.33323C16 6.59789 15.402 5.99989 14.6667 5.99989ZM14.6667 8.66656H13.3333V7.33323H14.6667V8.66656ZM14.6667 0.66656H13.3333C12.598 0.66656 12 1.26456 12 1.99989V3.33323C12 4.06856 12.598 4.66656 13.3333 4.66656H14.6667C15.402 4.66656 16 4.06856 16 3.33323V1.99989C16 1.26456 15.402 0.66656 14.6667 0.66656ZM14.6667 3.33323H13.3333V1.99989H14.6667V3.33323ZM14.6667 11.3332H13.3333C12.598 11.3332 12 11.9312 12 12.6666V13.9999C12 14.7352 12.598 15.3332 13.3333 15.3332H14.6667C15.402 15.3332 16 14.7352 16 13.9999V12.6666C16 11.9312 15.402 11.3332 14.6667 11.3332ZM14.6667 13.9999H13.3333V12.6666H14.6667V13.9999ZM10.6667 13.3332C10.6667 13.7019 10.368 13.9999 10 13.9999H6C2.692 13.9999 0 11.3086 0 7.99989C0 4.69123 2.692 1.99989 6 1.99989H8.674L7.82867 1.13323C7.57067 0.87056 7.57467 0.44856 7.838 0.19056C8.10067 -0.0674399 8.52267 -0.0634399 8.78067 0.199893L10.2807 1.72856C10.796 2.24389 10.796 3.08989 10.276 3.60923L8.78067 5.13323C8.65 5.26656 8.47733 5.33323 8.30467 5.33323C8.136 5.33323 7.96733 5.26989 7.838 5.14256C7.57467 4.88456 7.57133 4.46256 7.82867 4.19989L8.67867 3.33323H5.99933C3.426 3.33323 1.33267 5.42656 1.33267 7.99989C1.33267 10.5732 3.426 12.6666 5.99933 12.6666H9.99933C10.3673 12.6666 10.666 12.9646 10.666 13.3332H10.6667Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_26_3918">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

`,
        },
      },
      elementsToShow:[
        {title:'Description:',
          variable:'hideDescription',
         
        },
        {title:'Limit Description:',
          variable:'limitDescription',
         
        },
        {title:'Use Bullet Description:',
          variable:'useBulletDescription',
        },
        {title:'Product Rank:',
          variable:'showProductRank',
        },

      ]
    };
  },
  mounted() {
    this.rankOptionArr = this.block?.productRankOption || [];
    this.hidePrice = this.getEditorSettings.hidePrice;
  },
  methods: {
    updateSelectedBlock(block) {
      if (block == this.selectedBlock) {
        this.selectedBlock = "";
        return;
      }
      this.selectedBlock = block;
    },
    setViewType(value) {
      if (this.block.useSeperateMobileViewType && !this.isDesktop) {
        this.block.mobileViewType = value;
      } else {
        this.block.viewType = value;
      }
    },
    assignGroupProduct() {
      if (this.isUpperPlanUser) {
        this.block.viewType = "groupProducts";
      } else {
        let obj = {
          isOpen: true,
          title: "Upgrade to Utilize Group Product Feature",
          subText: `This enhancement lets you categorize products by tags, offering a more organized and targeted display in your quiz results. Tailor your quizzes to showcase products in distinct groups, enriching the user experience. For a comprehensive guide on implementing this feature, please visit: <a href="https://support.quizell.com/blogs/how-to-use/how-to-use-the-group-products-feature" target="_blank" style="color:#FFC000;text-decoration:underline">How to Use the Group Product Feature</a>.`,
        };

        this.setUpgradePlansModal(obj);
      }
    },
    updateHidePriceFunc() {
      this.updatehidePrice(this.hidePrice);
    },
    ...mapMutations([
      "setUpgradePlansModal",
      "updatehidePrice",
      "setUpgradePlansModal",
    ]),
    openUpgradePlansModalForAddToCart(mode) {
      if (!this.showAddToCartAndVariant) {
        let obj;
        if (mode == "cart") {
          obj = {
            isOpen: true,
            title: "Upgrade for Direct Add to Cart",
            subText: `Enhance your quiz functionality by upgrading to include the 'Add to Cart' feature. This powerful tool allows quiz takers to directly add products to their shopping cart while engaging with your quiz`,
          };
        } else {
          obj = {
            isOpen: true,
            title: "Upgrade Your Plan To Access More!",
            subText: `It seems you're trying to use a feature that isn't included in your current subscription plan. To unlock this and more amazing features, please consider upgrading your account.`,
          };
        }

        this.setUpgradePlansModal(obj);
      }
    },
    goToConfiguration(selectedTab) {
      this.$router.push({
        name: "Connect",
        query: { selectedTab: selectedTab },
      });
    },
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorOutput(value) {
      let isHex = this.checkHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
    deleteRankOption(index) {
      this.$emit("deleteSingleProductRankOption", index);
    },
    addRankOption() {
      this.$emit("AddSingleProductRankOption");
    },
    hasKeyValuePairs(obj, keyValuePairs) {
      for (const [key, value] of Object.entries(keyValuePairs)) {
        if (!(key in obj) || obj[key] !== value) {
          return false;
        }
      }

      return true;
    },

    async getGroups() {
      try {
        this.isFetchingGroupsLoading = true;
        let payload = {};
        if (this.getGroupBy.toLowerCase() == "tags") {
          payload.filters = ["tags"];
        } else {
          payload.filters = ["collections"];
        }

        const response = await axios.post(`/result/filter/group`, payload);
        if (response.status == 200) {
          if (this.getGroupBy.toLowerCase() == "tags") {
            this.groupProducts = response.data.data.tags;
          } else {
            this.groupProducts = response.data.data.collections;
          }

          // this.groupProducts = response.data.data;
        }
      } catch (error) {
        if (error) {
          console.log("Error", error);
        }
      } finally {
        this.isFetchingGroupsLoading = false;
      }
    },
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
    ...mapGetters([
      "getPlanData",
      "getNavbarData",
      "getEditorSettings",
      "getQuizID",
    ]),
    imageRatio(){
      return [{
        title:'1:1',
        value:'square'
      },{
        title:'2:3',
        value:'potrait'
      },
      {
        title:'3:2',
        value:'landscape'
      }]
    },
    imageScale(){
      return [{
        title:'Fit',
        value:'Fit'
      },{
        title:'Fill',
        value:'Fill'
      },
      ]
    },
    getEnv() {
      return this.getEditorSettings.env_contants;
    },
    getCurrQuizId() {
      return this.getQuizID;
    },
    checkIsCustomCollectionMedisClient() {
      return (
        this.getCurrQuizId == this.getEnv?.VUE_APP_COLLECTION_CLIENT_QUIZ_ID ??
        null
      );
    },
    UserPlanData() {
      return this.getPlanData;
    },
    isUpperPlanUser() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return allowedPlan.includes(this.UserPlanData.name);
    },
    getViewTypeValue() {
      if (this.block.useSeperateMobileViewType && !this.isDesktop) {
        return this.block.mobileViewType;
      } else {
        return this.block.viewType;
      }
    },
    getUserType() {
      return this.getNavbarData.userType;
    },
    showAddToCartAndVariant() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return (
        allowedPlan.includes(this.UserPlanData.name) &&
        this.getUserType == "shopify"
      );
    },
    getGroupBy() {
      return this.block?.groupProducts.groupBy;
    },
    getGroupOptions() {
      // return this.groupProducts.length > 0 ?    : []

      if (this.groupProducts.length > 0) {
        let groupOptions = [...this.groupProducts];

        return groupOptions.map((x) => x.title);
      }
      return [];
    },
    textBlockFont() {
      return this.block.style.fontFamily;
    },
    groupProductsFont() {
      return this.block.style.groupProductsStyle.fontFamily;
    },
    bulletDescriptionFont() {
      return this.block.style.bulletDescriptionfontFamily;
    },
    productVariantFont() {
      return this.block.style.productVariantStyles.fontFamily;
    },
    detailListFont() {
      return this.block.style.detailListFontFamily;
    },
    buttonFont() {
      return this.block.style.buttonFontFamily;
    },
  },

  watch: {
    getViewTypeValue: {
      handler(newValue) {
        if (newValue == "groupProducts") {
          this.getGroups();
        }
      },
      immediate: true,
    },
    getGroupBy: {
      handler(newValue) {
        if (newValue && this.getViewTypeValue == "groupProducts") {
          this.getGroups();
        }
      },
      // immediate: true,
    },
    textBlockFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
    groupProductsFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
    bulletDescriptionFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
    productVariantFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
    detailListFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
    buttonFont(newVal, oldVal) {
      if (oldVal && newVal) {
        this.$store.dispatch("updateFontFamily", {
          oldFontFamily: oldVal,
          newFontFamily: newVal,
        });
        this.$store.dispatch("loadFontsFromTracker");
      }
    },
  },
};
</script>
<style>
.GroupSelectContainer .multiSelect {
  text-align: start;
  direction: ltr !important;
}

.GroupSelectContainer > .multiSelect .multiselect__placeholder {
  color: #1f1f28;
  font-weight: 400;
  font-size: 14px;
}

.GroupSelectContainer > .multiselect .multiselect__tag {
  background: var(--secondary-color) !important;
  max-width: 220px;
}
.GroupSelectContainer > .multiselect .multiselect__tag-icon:after {
  color: #ffffff !important;
}

.GroupSelectContainer > .multiselect__content-wrapper {
  text-align: left !important;
  direction: ltr !important;
  z-index: 100;
  position: relative;
}
/* DropdownStyles */
.GroupSelectContainer > .multiselect .multiselect__option--highlight,
.GroupSelectContainer > .multiselect .multiselect__option--highlight:after {
  background: var(--secondary-color) !important;
}

.productCardSVG.selected path,
.imageRatioSVG.selected path {
  fill: #4d1b7e;
}


.product-match-card {
  border-radius: 12px;
  border: 1px solid rgba(24, 25, 28, 0.08);
  background: #f9f9f9;
}
.editor-options-type-list {
  border-radius: 6px;
  border: 1px solid #d2d8e0;
  background: var(--White, #fff);
  padding: 6px 12px;
}
.selected-option-btns {
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.selected-option-btns:hover {
  color: #18191c !important;
  cursor: pointer;
}
.option-radio-button input[type="radio"] {
  accent-color: var(--primary-color);
}

.selected-option-btn {
  color: #18191c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.flip-list-move {
  transition: transform 0.5s;
}
.select-per-page-count{
  padding: 6px 12px;
  border-radius: 6px;
background: #F7F7F7;
}
.select-per-page-count select,.select-per-page-count input{
  outline: none;
  background: transparent;
  border: none;
}
.select-per-page-count:focus-within{
  box-shadow: 0px 0px 1px 1px var(--secondary-color);
}
.element-card{
  border-radius: 6px;
  background: #F7F7F7;
}
.btn-arrangement{
  border-radius: 6px;
border: 1px solid #D2D8E0;
background: var(--White, #FFF);
padding: 6px 12px;
color: #535355;
font-size: 14px;
font-style: normal;
font-weight: 400;
transition:0.3s ease all;
}
.btn-arrangement:hover,.btn-active-arrangement{
  background: var(--primary-color);
  color:#fff;
}
.delete-option-button{
  color: #B3AFB6;
}
.delete-option-button:hover{
  cursor:pointer;
  color:#ED4A4B;
}
.add-new-option{
  color: var(--secondary-color);
  background: transparent;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */
border:none;
border-radius: 8px;
transition:0.3s ease all;
outline: none;
}
.add-new-option:hover{

  color:var(--primary-color);
}
</style>
<style>
.button-chevron{
  transition: 0.3s ease all;
}
:not(.collapsed) > .button-chevron {
  transform: rotate(180deg);
}
.collapsed > .button-chevron {
  transform: rotate(0deg);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
